import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/ef97312b-4b9c-4a08-84af-53f2a67cd31c/node_modules/next/dist/client/components/render-from-template-context.js");
